declare global {
  interface Window {
    RUN_ANALYTICS_AS_STANDALONE_APP?: boolean;
  }
}

window.RUN_ANALYTICS_AS_STANDALONE_APP = true;

// This is needed for webpack module federation
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import('./bootstrap');

// this is added to make typescript happy.
// If not, it will yell this error
// "Augmentations for the global scope can only be directly nested in external modules or ambient module declarations."
export {};
